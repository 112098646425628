import { useState } from "react";

import MediaQuery from "../../../components/common/media-query/media-query";
import { getReviewsByPage } from "../../../store/review/review.thunks";
import { useSetSeoPage } from "../../../hooks";
import { Hero } from "./hero/hero";
import { Partners } from "./partners/partners";
import { PartnersModal } from "./partners/partners-modal";

const Reviews = () => {
  useSetSeoPage();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen((prev) => !prev);

  return (
    <>
      <Hero handleOpen={handleOpen} />
      <MediaQuery minWidth={"bp576"}>
        <Partners />
      </MediaQuery>
      <MediaQuery maxWidth={"bp576"}>
        <PartnersModal visible={isOpen} cancelHandler={handleOpen} />
      </MediaQuery>
    </>
  );
};

Reviews.getServerSideState = async (store, params) => {
  const lang = params.locale;
  const state = await store.getState();

  const currentPage = 1;
  const { reviewsPreviewLength } = state.review;

  await store.dispatch(getReviewsByPage({ page: currentPage - 1, size: reviewsPreviewLength, localeCode: lang }));
};
export default Reviews;
