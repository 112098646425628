import { useEffect, useState } from "react";

import { Box, FormControlLabel, Switch, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Heading } from "../../../components/ui/heading/heading";

// import { useTranslation } from "../../../hooks";
import { style } from "./auto-renewal.style.js";
import ModalsAR from "./modalsAR";

export default function AutoRenewal({ proxyType, ipAddressesIds, onUpdateParentComponent }) {
  //translation
  const { t } = useTranslation();
  const autoRenewal = t("pages.cabinet.orders.widgets.autoRenewal");

  const [checked, setChecked] = useState(false);
  const [disabledSwitch, setDisabledSwitch] = useState(ipAddressesIds.length === 0);
  const initialModalState = { enable: false, disable: false };
  const [modalType, setModalType] = useState(initialModalState);
  const [alertDiffIps, setAlertDiffIps] = useState(false);

  useEffect(() => {
    if (ipAddressesIds.length > 0) {
      const firstAutoExtend = ipAddressesIds[0].autoExtend;
      const allMatch = ipAddressesIds.every((ip) => ip.autoExtend === firstAutoExtend);

      if (allMatch) {
        setChecked(firstAutoExtend);
        setDisabledSwitch(false);
        setAlertDiffIps(false);
      } else {
        setDisabledSwitch(true);
        setAlertDiffIps(true);
      }
    } else {
      setDisabledSwitch(true);
      setAlertDiffIps(false);
    }
  }, [ipAddressesIds]);

  const handleChange = (event) => {
    const { checked } = event.target;

    if (checked) {
      setModalType({ disable: false, enable: true });
    } else {
      setModalType({ disable: true, enable: false });
    }
    setChecked(checked);
  };

  const closeModal = () => {
    setModalType(initialModalState);
    setChecked(ipAddressesIds?.[0]?.autoExtend || false);
  };

  return (
    <>
      <Heading tag="h4" variant="h4">
        {autoRenewal.title}
      </Heading>

      {ipAddressesIds.length === 0 && (
        <Box component="p" sx={{ ...style.count, marginBottom: "1rem" }}>
          {autoRenewal.description}
        </Box>
      )}
      <Box sx={style.count}>
        {autoRenewal.selectedIp}: <span> {ipAddressesIds.length}</span>
      </Box>

      <FormControlLabel
        sx={style.switch}
        control={<Switch />}
        checked={checked}
        disabled={disabledSwitch}
        onChange={handleChange}
        label={
          ipAddressesIds.length > 0 &&
          autoRenewal[ipAddressesIds[0].autoExtend ? "disable" : "enable"]
        }
      />

      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: "1.2rem !important",
              maxWidth: 150,
              "& .MuiTooltip-arrow": {
                transform: "translate(10px, 0px) !important"
              }
            }
          }
        }}
        arrow
        placement="bottom-start"
        open={alertDiffIps}
        title={autoRenewal.selectIpsWithSameSettings}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10]
                }
              }
            ]
          }
        }}
      >
        <div></div>
      </Tooltip>
      {modalType.enable || modalType.disable ? (
        <ModalsAR
          onUpdateParentComponent={onUpdateParentComponent}
          proxyType={proxyType.includes("mobile") ? "mobile" : proxyType}
          ipAddressIds={ipAddressesIds.map(({ id }) => id)}
          modalType={modalType}
          closeModal={closeModal}
        />
      ) : null}
    </>
  );
}
