import React, { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FaqBlock } from "../../../components/common/faq-block/faq-block";
import ReviewsBlock from "../../../components/common/reviews-block/reviews-block";
import { FeaturesBlock } from "../../../components/proxy/features-block/features-block";
import { Hero } from "../../../components/proxy/hero/hero";
import { Seo } from "../../../components/proxy/seo/seo";
import { useLang, useSetSeoPage } from "../../../hooks";
import useProxyCountryOrType, {
  getProxyCountryOrTypeForSsr
} from "../../../hooks/use-proxy-country-or-type";
import { getFaqByParams } from "../../../store/faq/faq.thunks";
import { getProxyState } from "../../../store/proxy/proxy.selectors";
import { getCountriesPricesByProxyType } from "../../../store/proxy/proxy.thunks";
import { fetchSeoBlocks } from "../../../store/seo-block/seo-block.thunks";
import { ROUTE } from "../../../utils/constants";
import { getSearchParams } from "../../../utils/helpers";

import CountryProductMeta from "./country-product-meta/country-product-meta";
import PricesForCountry from "./prices-for-country/prices-for-country";

const CountryProxy = () => {
  useSetSeoPage();

  const lang = useLang();
  const navigate = useNavigate();

  const { country: countrySlug, type: proxyType } = useProxyCountryOrType();
  const { proxies } = useSelector(getProxyState);

  const getCountry = useCallback(() => {
    return proxies["IPV4"]?.countries?.find((el) => el.urlParam === countrySlug);
  }, [proxies, countrySlug]);

  const [country, setCountry] = useState(() => getCountry());

  useEffect(() => {
    if (proxies["IPV4"]) {
      const country = getCountry();

      if (!country) {
        navigate(`/${lang}${ROUTE.APP.NOT_FOUND}/`, { replace: true });
      } else {
        setCountry(country);
      }
    }
  }, [proxies]);

  const countryName = country?.namesDependingOnLocale[lang] ?? "";

  return (
    <>
      <CountryProductMeta
        proxyType={proxyType}
        countryName={countryName}
        countrySlug={countrySlug}
        locale={lang}
      />
      <Hero countryOrTypeName={countryName} />
      <PricesForCountry country={country} proxyType={proxyType} />
      <FeaturesBlock countryOrTypeName={countryName} lang={lang} countryName={countrySlug} />
      <ReviewsBlock />
      <FaqBlock location={"ipv4"} country={countrySlug} />
      <Seo />
    </>
  );
};

CountryProxy.getServerSideState = async (store, params) => {
  const lang = params.locale;

  const searchCountry = getSearchParams(params.location)?.country;
  const proxyCountry = params.params?.proxyCountry;

  const { type } = getProxyCountryOrTypeForSsr(searchCountry, proxyCountry, params.pathname);

  // Prices for country
  await store.dispatch(getCountriesPricesByProxyType(type.toUpperCase()));

  // Features Block
  await store.dispatch(
    fetchSeoBlocks({
      keys: ["proxyFeaturesBlock", "proxyCountryPageBatchProxies", "proxyCountryPageEnterprise"],
      languageCode: lang
    })
  );
  // Faq Block
  await store.dispatch(
    getFaqByParams({
      type: "faq",
      location: "ipv4",
      language: lang,
      params: { country: proxyCountry }
    })
  );
};
export default CountryProxy;
