import { useSelector } from "react-redux";

import { FaqBlock } from "../../../components/common/faq-block/faq-block";
import ReviewsBlock from "../../../components/common/reviews-block/reviews-block";
import { FeaturesBlock } from "../../../components/proxy/features-block/features-block";
import { Hero } from "../../../components/proxy/hero/hero";
import { Seo } from "../../../components/proxy/seo/seo";
import { useLang, useSetSeoPage } from "../../../hooks";
import useProxyCountryOrType, {
  getProxyCountryOrTypeForSsr
} from "../../../hooks/use-proxy-country-or-type";
import { getFaqByParams } from "../../../store/faq/faq.thunks";
import { getCountriesPricesByProxyType } from "../../../store/proxy/proxy.thunks";
import { getProxyState } from "../../../store/selectors";
import { fetchSeoBlocks } from "../../../store/seo-block/seo-block.thunks";
import { NOT_INDEXED_PROXY_TYPES } from "../../../utils/constants";
import { getSearchParams } from "../../../utils/helpers";

import Prices from "./prices/prices";
import ProxyProductMeta from "./proxy-product-meta/proxy-product-meta";

const Proxy = () => {
  useSetSeoPage();
  const lang = useLang();
  const { type, country } = useProxyCountryOrType();

  const countryOrType = NOT_INDEXED_PROXY_TYPES.find((el) => type === el);

  const proxyType = countryOrType.toLocaleLowerCase();
  const { proxies } = useSelector(getProxyState);
  const firstCountry = proxies[countryOrType.toUpperCase()].countries[0].urlParam;

  const countryName = country ?? firstCountry;
  return (
    <>
      <ProxyProductMeta proxyType={type} countrySlug={country} />
      <Hero countryOrTypeName={countryOrType} />
      <Prices proxyType={countryOrType} />
      <FeaturesBlock countryOrTypeName={countryOrType} lang={lang} countryName={countryName} />
      <ReviewsBlock />
      <FaqBlock location={proxyType} country={countryName} />
      <Seo />
    </>
  );
};

Proxy.getServerSideState = async (store, params) => {
  const lang = params.locale;
  const { proxy } = await store.getState();
  const searchCountry = getSearchParams(params.location)?.country;
  const proxyCountry = params.params?.proxyCountry;

  const { type } = getProxyCountryOrTypeForSsr(searchCountry, proxyCountry, params.pathname);
  const firstCountry = proxy.proxies[type.toUpperCase()].countries[0].urlParam;
  await store.dispatch(getCountriesPricesByProxyType(type));

  // Features Block
  await store.dispatch(
    fetchSeoBlocks({ keys: ["proxyFeaturesBlock", "proxyPageCountriesTable"], languageCode: lang })
  );
  // Faq Block
  const countryName = searchCountry ?? firstCountry;
  const reqParams = {
    type: "faq",
    location: type.toLocaleLowerCase(),
    language: lang,
    params: { country: countryName }
  };
  await store.dispatch(getFaqByParams(reqParams));
};
export default Proxy;
