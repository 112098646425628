import React, { useEffect, useRef, useState } from "react";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Form } from "../../../../components/form";
import { Button } from "../../../../components/ui";
import { useActions, useLang } from "../../../../hooks";
import { UserService } from "../../../../services";
import { getCaptchaTokenFromFormData } from "../../../../utils/helpers";
import { EmailChangeShema } from "../../../../utils/validation";
import { style } from "../profile.style";

const ProfileChangeEmailForm = () => {
  const captchaRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const lang = useLang();
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("emailHash") || null;
  const newEmail = searchParams.get("email") || null;
  const { setUser } = useActions();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handlerSubmitSendLink = (data) => {
    setLoading(true);

    UserService.sendEmailLink(data.newEmail, getCaptchaTokenFromFormData(data))
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject({ response });
        }

        enqueueSnackbar(t("messages.linkToTheChangeHasBeenSentByEmail"), { variant: "success" });
      })
      .catch((error) => {
        const statusCode = error?.response?.status;
        let message = t("serverError");

        if (statusCode === 208) {
          message = t("messages.emailAlreadyInUse");
        } else if (statusCode === 403) {
          message = t("messages.temporaryEmails");
        } else if (statusCode === 406) {
          message = t("form.rules.email.invalid");
        } else if (statusCode === 409) {
          captchaRef?.current?.reset();
          message = t("messages.captchaFailed");
        }

        enqueueSnackbar(message, { variant: "error" });
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        captchaRef?.current?.reset();
      });
  };

  useEffect(() => {
    if (hash && newEmail) {
      setLoading(true);

      UserService.changeEmail(hash)
        .then((response) => {
          if (response.status !== 200) {
            return Promise.reject({ response });
          }

          enqueueSnackbar(t("messages.emailChanged"), { variant: "success" });

          setTimeout(() => {
            setUser(null);
            navigate(`/${lang}/auth/login/`, { replace: true });
          }, 1500);
        })
        .catch((error) => {
          let message = t("serverError");

          if (error?.response?.status === 401) {
            message = t("messages.logInWithYourPreviousCredentials");
          } else if (error?.response?.status === 204) {
            message = t("messages.userNotFoundByHash");
          } else if (error?.response?.status === 406) {
            message = t("form.rules.email.invalid");
          }

          enqueueSnackbar(message, { variant: "error" });
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [hash, newEmail]);

  return (
    <div>
      <Form
        sx={style.form}
        schema={EmailChangeShema(t("form"))}
        defaultValues={{
          newEmail: newEmail ?? ""
        }}
        onSubmit={handlerSubmitSendLink}
      >
        <Form.Input
          name="newEmail"
          label={t("form.labels.newEmail")}
          labelType="outer"
          variant="outlined"
        />

        <Form.Captcha ref={captchaRef} id="change-email-captcha" />

        <Button disabled={loading} type="submit" variant="primary" fullwidth>
          {t("form.actions.applyChange")}
        </Button>
      </Form>
    </div>
  );
};

export default ProfileChangeEmailForm;
