/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { Modal } from "../../../components/ui/modal/modal";
import { useActions, useLang, useTranslateOptions } from "../../../hooks";
import useOrder from "../../../hooks/use-order";
import { OrdersService } from "../../../services";
import { StoreActions } from "../../../store/actions";
import {
  getFingerprintState,
  getMobileState,
  getPaymentState,
  getProxyState,
  getRentState,
  getUserState
} from "../../../store/selectors";
import {
  AUTH_METHODS_OPTIONS,
  CALC_TYPES,
  IP_ORDER_OPTIONS,
  MODAL_ORDER_STATUS
} from "../../../utils/constants";
import {
  getCountriesOptions,
  getIndexFromOptions,
  getOperatorOptions,
  getOperatorRotations,
  getPeriodsOptions,
  getPriceCalculationType,
  getRotationsOptions,
  makeOrder
} from "../../../utils/helpers/order.helpers";
import { orderProcessing } from "../../../utils/helpers/orderProcessing.helpers";
import FormCaptcha from "../form-captcha/form-captcha";
import { MessageCard } from "../message-card/message-card";
import { ModalRadioGroup } from "../modal-radio-group/modal-radio-group";
import { PromocodeSection } from "../promocode-section/promocode-section";
import { SelectList } from "../select-list/select-list";
import { SelectWithBalance } from "../select-with-balance/select-with-balance";
import { TextInput } from "../text-input/text-input";

import SummarySection from "./common/summary-section";
import { style } from "./order-modal.style";

export const OrderModal = (props) => {
  const { id, initialData, visible, cancelHandler, quantityOptions, orderStatus } = props;
  const { enqueueSnackbar } = useSnackbar();
  const captchaRef = useRef(null);
  const [cfToken, setCfToken] = useState("");
  // --HOOKS
  const order = useOrder(initialData);
  const lang = useLang();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fetchMobile } = useActions();
  const { countries } = useSelector(getMobileState);
  // --Selectors
  const { systemsOptions } = useSelector(getPaymentState);
  const { proxies } = useSelector(getProxyState);
  const { periods } = useSelector(getRentState);
  const protocolOptions = useTranslateOptions(IP_ORDER_OPTIONS, t("ipType"));
  const authMethods = useTranslateOptions(AUTH_METHODS_OPTIONS, t("authMethods"));
  const { user, isAuthenticated } = useSelector(getUserState);
  const { data } = useSelector(getFingerprintState);
  // --Local State
  const [mobileProxyOptions, setMobileProxyOptions] = useState([]);
  const [customGoalSearchOptions, setCustomGoalSearchOptions] = useState([]);
  const [submittable, setSubmittable] = useState(false);
  const [disableMail, setDisableMail] = useState(false);
  const [hasPromo, setHasPromo] = useState(false);
  const [customGoalRequest, setCustomGoalRequest] = useState(null);
  const [trigeredField, setTrigeredField] = useState(null);

  const errorWithSnack = (status) => {
    const message =
      status !== 403 ? t("form.serverErrors.incorrectEmail") : t("messages.temporaryEmails");
    enqueueSnackbar(message, { variant: "error" });
    order.setErrors({ email: true });
  };

  // --Helpers
  const setOrder = (payload) => {
    order.setData((prev) => ({ ...prev, ...payload }));
  };
  const [status, setStatus] = useState(orderStatus ?? MODAL_ORDER_STATUS.DEFAULT);
  const errorFunction = ({ resetCaptcha }) => {
    setStatus(MODAL_ORDER_STATUS.ERROR);

    if (resetCaptcha) {
      captchaRef?.current?.reset();
    }
  };
  const systemOptionsForUser = systemsOptions
    ? systemsOptions[lang].filter(({ showForUser, code }) =>
        !isAuthenticated ? showForUser && code !== "balance" : showForUser
      )
    : [];

  // --Select Options

  const quantityIndex = getIndexFromOptions(quantityOptions ?? [], "key", order.data.proxyCount);

  const countryOptions = getCountriesOptions(order.data.proxyType, proxies, lang);
  const mobileOperatorOptions = getOperatorOptions(mobileProxyOptions);
  const rentalPeriodOptions = getPeriodsOptions(order.data.proxyType, periods, lang);

  const countryIndex = getIndexFromOptions(countryOptions, "key", order.data.proxyCountry);
  const rentalPeriodIndex = getIndexFromOptions(
    rentalPeriodOptions,
    "key",
    order.data.proxyRentalPeriod
  );
  const protocolIndex = getIndexFromOptions(protocolOptions, "key", order.data.proxyProtocol, null);
  const authTypeIndex = getIndexFromOptions(authMethods, "key", order.data.proxyAuth, null);
  const paymentTypeIndex = getIndexFromOptions(
    systemOptionsForUser,
    "key",
    order.data.proxyPayment,
    null
  );
  const mobileOperatorIndex = getIndexFromOptions(
    mobileOperatorOptions,
    "id",
    order.data.mobileOperatorId,
    null
  );

  const operatorRotations = getOperatorRotations(mobileProxyOptions[mobileOperatorIndex]);
  const operatorRotationsOptions = getRotationsOptions(operatorRotations, t);
  const modemRotationMinIndex = getIndexFromOptions(
    operatorRotationsOptions,
    "value",
    order.data.modemRotationMin,
    null
  );
  useEffect(() => {
    if (mobileProxyOptions?.length) {
      setOrder({ currencyId: mobileProxyOptions[0]?.currencyId ?? "" });
    }
  }, [mobileProxyOptions, initialData]);
  // Fetch mobile content
  useEffect(() => {
    if (order.data.proxyType === "MOBILE" && !countries.length) {
      fetchMobile();
    }
  }, [order.data.proxyType]);
  // Set setMobileOperators
  useEffect(() => {
    if (order.data.proxyType === "MOBILE" && order.data.proxyCountry) {
      const operators = countries.find((e) => e.id === order.data.proxyCountry)?.mobileOperators;
      setMobileProxyOptions(operators ?? []);
    }
  }, [order.data.proxyType, order.data.proxyCountry, countries]);
  // Toggle promocode input
  useEffect(() => {
    setHasPromo(!!order.data.promoCode);
  }, [order.data.promoCode]);
  // set email if Authenticated
  useEffect(() => {
    if (visible) {
      setOrder({ proxyMail: user?.email ? user?.email : order.data.proxyMail });
      setDisableMail(!!user?.email);
    }
  }, [user?.email, visible]);
  // set setCustomGoalSearchOptions
  useEffect(() => {
    if (visible) {
      const dispatchGoal = setTimeout(() => {
        if (customGoalRequest && order.data.proxyCustomGoal) {
          OrdersService.getOrderGoals(
            encodeURIComponent(order.data.proxyCustomGoal).replace(/%20/g, "+")
          ).then((res) => {
            if (res.data) setCustomGoalSearchOptions(res.data);
            else setCustomGoalSearchOptions([]);
          });
        }
      }, 200);

      return () => clearTimeout(dispatchGoal);
    }
  }, [order.data.proxyCustomGoal, customGoalRequest]);
  // calculate price
  useEffect(() => {
    if (visible) {
      order.calculatePrice(getPriceCalculationType(order));
    }
  }, [order.data]);
  // set submittable
  useEffect(() => {
    if (order.price.error === "BALANCE_NO_AUTH") {
      dispatch(StoreActions.setUser(null));
    }
    setSubmittable(!order.price.error);
  }, [order.price.error]);
  // --Handlers
  const closeHandler = () => {
    setStatus(MODAL_ORDER_STATUS.DEFAULT);
    cancelHandler();
  };
  const errorCancelHandler = () => {
    setStatus(MODAL_ORDER_STATUS.DEFAULT);
  };
  const handlerChangeCountry = (option) => {
    setOrder({
      proxyCountry: option.key,
      mobileOperatorId: "",
      modemRotationMin: ""
    });
  };
  const handlerChangeMobileOperator = (option) => {
    setOrder({
      mobileOperatorId: option.id,
      modemRotationMin: ""
    });
  };

  const handlerOpenMobileOperator = (setOpen) => {
    if (!order.data.proxyCountry) {
      setTrigeredField("country");
      setOpen(false);
    }
  };
  const handlerChangeRotation = (option) => {
    // console.log(option);
    // const modemRotationMinArray = mobileProxyOptions[mobileOperatorIndex]?.rotationMinutes;
    const modemRotationMin = option.value;

    setOrder({ modemRotationMin });
  };
  const handlerOpenRotationMin = (setOpen) => {
    if (!order.data.proxyCountry) {
      setTrigeredField("country");
      setOpen(false);
    } else if (!order.data.mobileOperatorId) {
      setTrigeredField("mobileOperator");
      setOpen(false);
    }
  };
  const handlerChangeCustomGoal = (e) => {
    setOrder({ proxyCustomGoal: e.target.value });
    setCustomGoalRequest(e.target.toRequest);
  };
  const handlerChangeRentalPeriod = (option) => {
    setOrder({ proxyRentalPeriod: option.key });
  };
  const submitHandler = async () => {
    const errors = order.validateData();
    if (errors.authIp) {
      enqueueSnackbar(t("shared.orderModal.ipValidationError"), {
        variant: "error"
      });
    }

   if (errors.customGoal.length > 0) {
      enqueueSnackbar(errors.customGoal, {
        variant: "error"
      });
    }
    if (!Object.values(errors).some(Boolean)) {
      setSubmittable(false);

      const resp = await makeOrder(
        order.data,
        order.price,
        lang,
        data?.visitorId,
        errorFunction,
        errorWithSnack,
        cfToken
      );
      if (resp) {
        orderProcessing(resp);
        order.clearData();
        order.calculatePrice(CALC_TYPES.BASIC);
        cancelHandler();
        captchaRef.current?.reset();
      }
      setSubmittable(true);
    }
  };

  return (
    <Modal visible={visible} cancelHandler={closeHandler}>
      {status === MODAL_ORDER_STATUS.DEFAULT ? (
        <Box sx={style.modal}>
          <Box sx={style.modalUpper}>
            <Heading tag="h4" variant="h4">
              {t("shared.orderModal.title")}
            </Heading>
            <Box component="span" sx={style.subtitle}>
              {t("shared.orderModal.description")}
            </Box>

            <Box sx={style.fieldGrid}>
              <SelectList
                isOpen={trigeredField === "country"}
                onClose={() => {
                  if (trigeredField === "country") {
                    setTrigeredField(null);
                  }
                }}
                className="order-modal__item"
                name="country"
                label={t("form.labels.country")}
                options={countryOptions}
                onChange={handlerChangeCountry}
                selectedIndex={countryIndex}
                withIcon={true}
                error={order.dataErrors["country"]}
              />

              {order.data.proxyType === "MOBILE" ? (
                <>
                  <SelectList
                    isOpen={trigeredField === "mobileOperator"}
                    onClose={() => {
                      if (trigeredField === "mobileOperator") {
                        setTrigeredField(null);
                      }
                    }}
                    className="order-modal__item"
                    name="mobileOperator"
                    label={t("form.labels.mobileOperator")}
                    options={mobileOperatorOptions}
                    onChange={handlerChangeMobileOperator}
                    onOpen={handlerOpenMobileOperator}
                    selectedIndex={mobileOperatorIndex}
                    error={order.dataErrors["mobileOperator"]}
                  />
                  <SelectList
                    // disabled={!operatorRotations.length}
                    className="order-modal__item"
                    name="modemRotationMin"
                    label={t("form.labels.modemRotationMin")}
                    options={operatorRotationsOptions}
                    onChange={handlerChangeRotation}
                    onOpen={handlerOpenRotationMin}
                    selectedIndex={modemRotationMinIndex}
                    error={order.dataErrors["modemRotationMin"]}
                  />
                </>
              ) : (
                <TextInput
                  className="order-modal__item"
                  customStyles={style.textInput}
                  name="customGoal"
                  onChange={handlerChangeCustomGoal}
                  label={null}
                  placeholder={t("form.placeholders.enterCustomGoal")}
                  error={order.dataErrors["customGoal"]}
                  value={order.data.proxyCustomGoal}
                  isSearchInput
                  searchOptions={customGoalSearchOptions}
                />
              )}

              {quantityOptions?.length ? (
                <SelectList
                  className="order-modal__item"
                  name="quantity"
                  label={t("form.labels.proxyCount")}
                  options={quantityOptions}
                  selectedIndex={quantityIndex}
                  onChange={(e) => setOrder({ proxyCount: e.key })}
                  error={order.dataErrors["quantity"]}
                  errorText={order.dataErrors["quantity"]}
                />
              ) : (
                <TextInput
                  className="order-modal__item"
                  customStyles={style.textInput}
                  name="quantity"
                  onlyNumber={true}
                  onChange={(e) => setOrder({ proxyCount: e.target.value })}
                  value={order.data.proxyCount}
                  label={t("form.labels.proxyCount")}
                  error={order.dataErrors["quantity"]}
                  errorText={order.dataErrors["quantity"]}
                />
              )}

              <SelectList
                className="order-modal__item"
                name="rentalPeriod"
                label={t("form.labels.rentalPeriod")}
                options={rentalPeriodOptions}
                selectedIndex={rentalPeriodIndex}
                onChange={handlerChangeRentalPeriod}
                error={order.dataErrors["rentalPeriod"]}
              />

              {order.data.proxyType === "IPV6" ? (
                <SelectList
                  className="order-modal__item"
                  name="proxyProtocol"
                  label={t("form.labels.proxyProtocol")}
                  options={protocolOptions}
                  selectedIndex={protocolIndex}
                  onChange={(option) => setOrder({ proxyProtocol: option.key })}
                  error={order.dataErrors["proxyProtocol"]}
                />
              ) : null}
            </Box>

            {/* CUSTOM AUTH METHOD RADIO GROUP */}
            <ModalRadioGroup
              label={t("form.labels.authType")}
              options={authMethods}
              selectedIndex={authTypeIndex}
              ipInputKey={"ip"}
              onChange={(e) => setOrder({ proxyAuth: e.target.value })}
              cancelHandler={() =>
                setOrder({
                  proxyAuth: "login",
                  proxyIpAuth: ""
                })
              }
              error={order.dataErrors["authType"]}
              ipError={order.dataErrors["authIp"]}
              onIpChange={(e) => setOrder({ proxyIpAuth: e.target.value })}
              ipValue={order.data.proxyIpAuth}
            />
          </Box>

          <Box sx={style.modalBottom}>
            <Box sx={[style.fieldGrid, { marginBottom: "1.2rem" }]}>
              <TextInput
                isOuterLabel
                className="order-modal__item"
                customStyles={style.textInput}
                name="clientEmail"
                placeholder={t("form.placeholders.enterEmail")}
                onChange={(e) => setOrder({ proxyMail: e.target.value })}
                value={order.data.proxyMail}
                error={order.dataErrors["email"]}
                disabled={disableMail}
              />

              <SelectWithBalance
                className="order-modal__item"
                name="paymentType"
                isOuterLabel
                placeholder={t("form.placeholders.paymentMethod")}
                options={systemOptionsForUser}
                selectedIndex={paymentTypeIndex}
                onChange={(option) => setOrder({ proxyPayment: option.key })}
                error={order.dataErrors["paymentType"]}
                errorText={order.dataErrors["paymentType"]}
              >
                <SelectList />
              </SelectWithBalance>

              <PromocodeSection
                hasPromo={hasPromo}
                promo={order.data.promoCode}
                onChange={(e) => setOrder({ promoCode: e.target.value })}
                onClose={() => {
                  setHasPromo(false);
                  setOrder({ promoCode: "" });
                }}
                onClick={() => setHasPromo(true)}
                promoLinkStyles={style.promocode}
                inputProps={{
                  isOuterLabel: true,
                  error: !order.price?.validPromoCode,
                  errorText: order.dataWarnings.promoCode
                }}
              />
            </Box>

            {/* summary info || error block */}
            <Box sx={style.info}>
              <SummarySection priceObj={order.price} />
            </Box>

            <FormCaptcha
              id={id ? `form-captcha-for-${id}` : null}
              ref={captchaRef}
              onSuccess={(token) => setCfToken(token)}
              sx={{
                mb: "1rem"
              }}
            />

            <Button
              onClick={submitHandler}
              type="submit"
              variant="primary"
              ariaLabel="submit"
              fullwidth
              disabled={!submittable}
            >
              {t("form.actions.makeOrder")}
            </Button>
          </Box>
        </Box>
      ) : null}

      {status === MODAL_ORDER_STATUS.ERROR ? (
        <MessageCard
          type="order"
          iconPath={"/images/icons/order/error-order.svg"}
          title={t("order.messages.error.title")}
          description={t("order.messages.error.description")}
          actionLabel={t("common.tryAgain")}
          actionEvent={() => errorCancelHandler()}
        />
      ) : null}
    </Modal>
  );
};
