import React from "react";
import { Box } from "@mui/material";
import { Button } from "../../ui";
import { style } from "./feature-card.style";

export const FeatureCard = (props) => {
  const {
    icon,
    title,
    description,
    button,
    ButtonProps,
    customStyles = {}
  } = props;

  return (
    <Box sx={[style.wrapper, customStyles]}>
      <Box>
        <Box sx={style.head}>
          <Box component={icon} />
          <Box>{title}</Box>
        </Box>
        <Box sx={style.description}>{description}</Box>
      </Box>
      <Box sx={style.footer}>
        {button ? ( <Button
          to={button.link}
          size="medium"
          {...ButtonProps}
        >{button.text}</Button>
        ) : null}
      </Box>
    </Box>
  );
};
