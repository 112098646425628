
export const LOCALS_LINK = [
  {
    rel: "alternate",
    hrefLang: "en",
    href: `/en/`
  },
  {
    rel: "alternate",
    hrefLang: "ru",
    href: `/ru/`
  },
  {
    rel: "alternate",
    hrefLang: "uk",
    href: `/uk/`
  }
];
