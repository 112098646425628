import React, { useEffect, useState } from "react";

import { Box, ButtonBase, Fade, Popper } from "@mui/material";
// import { useTranslation } from "../../../hooks";
import { useTranslation } from "react-i18next";

import { ArrowIcon } from "../../../assets/images/icons/arrow-icon";

import { style } from "./discount-offer-tooltip.style";

const DiscountOfferTooltip = (props) => {
  const { anchorEl, open, data, onSubmit, onClose, autoHideDuration } = props;

  const { t } = useTranslation();

  const [arrowRef, setArrowRef] = useState(null);
  const [inFocus, setInFocus] = useState(null);
  const [disableAutoHide, setDisableAutoHide] = useState(false);

  useEffect(() => {
    if (open && !disableAutoHide && autoHideDuration) {
      const timer = setTimeout(() => onClose(), autoHideDuration);

      return () => clearTimeout(timer);
    }
  }, [open, disableAutoHide, autoHideDuration]);

  useEffect(() => {
    if (open) {
      setInFocus(true);
      setDisableAutoHide(false);
    }
  }, [open]);

  useEffect(() => {
    if (!inFocus) {
      const timer = setTimeout(() => onClose(), 1500);

      return () => clearTimeout(timer);
    }
  }, [inFocus]);

  return (
    <Popper
      keepMounted={false}
      open={open}
      anchorEl={anchorEl}
      slotProps={{
        root: {
          style: style.popover
        }
      }}
      modifiers={[
        {
          name: "flip",
          enabled: false
        },
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef
          }
        }
      ]}
      placement="bottom-start"
      onMouseEnter={() => {
        setDisableAutoHide(true);
        setInFocus(true);
      }}
      onMouseLeave={() => setInFocus(false)}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box>
            <Box ref={setArrowRef} sx={style.arrow}>
              <ArrowIcon />
            </Box>
            <Box sx={style.content} onBlur={onClose}>
              <ButtonBase sx={style.button} onClick={(e) => onSubmit(e, data.nextQuantity)}>
                {t("discountOfferTooltip.addButton")}
              </ButtonBase>{" "}
              {t("discountOfferTooltip.discountOfferText")
                .replace("{{count}}", data.nextQuantity - data.currentQuantity)
                .replace("{{discountInPercent}}", data.nextAmount)}
            </Box>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};

export default DiscountOfferTooltip;
